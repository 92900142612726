.tileContainer {
  margin-top: 150px;
  margin-right: 50px;
  margin-left: 50px;
}

.modalLocationTitle {
  text-shadow: 2px 2px black;
  color: white;
  font-size: 40px;
  margin: 0;
  position: absolute;
  left: 35%;
  top: 30%
}

.modalGridName{
  padding-left: 30px;
  /* padding-top: 20px; */
}

.locationTitle {
  text-shadow: 2px 2px black;
  color: white;
  font-size: 30px;
  margin: 0;
  position: absolute;
  left: 20%;
  top: 40%
}

.modalStyle{
  position:absolute;
  top:50%;
  left:50%;
  transform: translate(-50%, -50%);
  width:70%;
  height: 69vh;
  box-shadow: 24;
  border-radius: 15px;
  overflow: hidden;
  overflow: scroll;
  outline: none !important;
}
.mapGrid{
  padding-top: 30px;
}
.modalProgress{
  padding-right: 10px;
}
@media (max-width: 991px) {
  .modalLocationTitle {
      font-size: 25px;
  }
  .locationTitle{
    font-size: 20px;
      left: 10%;
      top: 40%;
      width:80%
  }
  .modalStyle{
    position:absolute;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
    width:90%;
    height: 69vh;
    box-shadow: 24;
    border-radius: 15px;
    overflow: hidden;
    overflow: scroll;
    outline: none !important;
  }
  .modalGridName{
    padding-left: 5px;
    padding-bottom: 5px;
  }
  .modalProgress{
    padding-bottom: 30px;
    padding-left: 5px;
    padding-right: 5px;
  }
  .mapGrid{
  padding-top: 0px;
}
}