

.blogTitle {
    text-align: center;
    align-items: center;
    font-size: 30px;
}

@media (max-width: 991px) {
    .blogTitle {
        font-size: 18px;
    }
    .postContainer{
        padding-top:150px;
        padding-left:0px
    }
}