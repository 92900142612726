@import url("https://fonts.googleapis.com/css?family=Poppins:200,300,400,500,600,700,800,900&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  /* background: #90beab; */
}
.divPad {
  margin: 20px;
}
header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 40px 100px;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
header .logo {
  color: #fff;
  width: 10%;
  text-transform: uppercase;
  border-radius: 5px;
  cursor: pointer;
}
.toggle {
  position: relative;
  width: 60px;
  height: 60px;
  background: url(https://i.ibb.co/HrfVRcx/menu.png);
  background-repeat: no-repeat;
  background-size: 30px;
  background-position: center;
  cursor: pointer;
}
.toggle.active {
  background: url(https://i.ibb.co/rt3HybH/close.png);
  background-repeat: no-repeat;
  background-size: 25px;
  background-position: center;
  cursor: pointer;
}
.showcase {
  position: absolute;
  right: 0;
  width: 100%;
  height: 100vh;
  padding: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #90beab;
  transition: 0.5s;
  z-index: 2;
}
.showcase.active {
  right: 300px;
}

.showcase video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.8;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #90beab;
  mix-blend-mode: overlay;
}
.text {
  position: relative;
  z-index: 10;
}

.text h2 {
  font-size: 5em;
  font-weight: 800;
  color: #fff;
  line-height: 1em;
  text-transform: uppercase;
}
.text h3 {
  font-size: 4em;
  font-weight: 700;
  color: #fff;
  line-height: 1em;
  text-transform: uppercase;
}
.text p {
  font-size: 1.1em;
  color: #fff;
  margin: 20px 0;
  font-weight: 400;
  max-width: 700px;
}
.text a {
  display: inline-block;
  font-size: 1em;
  background: #fff;
  padding: 10px 30px;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 500;
  margin-top: 10px;
  color: #111;
  letter-spacing: 2px;
  transition: 0.2s;
}
.text a:hover {
  letter-spacing: 6px;
}
.social {
  position: absolute;
  z-index: 10;
  bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.social li {
  list-style: none;
}
.social li a {
  display: inline-block;
  margin-right: 20px;
  filter: invert(1);
  transform: scale(0.5);
  transition: 0.5s;
}
.social li a:hover {
  transform: scale(0.5) translateY(-15px);
}
.menu {
  position: absolute;
  top: 0;
  right: 0;
  width: 300px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.menu ul {
  position: relative;
}
.menu ul li {
  list-style: none;
}
.menu ul li a {
  text-decoration: none;
  font-size: 24px;
  color: #111;
}
.menu ul li a:hover {
  color: #03a9f4;
}

@media (max-width: 991px) {
  .showcase,
  .showcase header {
    padding: 40px;
  }
  .showcase{
    height:120vh;
  }
  .exploreButton{
  }
  .social{
    padding-top:0px
  }
  .logo {
  }
  .text {
  }
  .text h2 {
    /* padding-top:10px; */
    font-size: 3em;
  }
  .text h3 {
    font-size: 2em;
  }
  .nextBlock {
    padding: 0px;
    height: 100vh;
  }
  
  .blogBlock{
    padding-top: 0px;
    /* height: 100vh; */
  
  }
}

.nextBlock {
  padding: 30px;
  height: 100vh;
}

.blogBlock{
  padding: 50px;
  /* height: 100vh; */

}

h1 {
  text-shadow: 0 1px 2px #333;
  color: white;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.words {
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  height: 50px;
  width: 300px;
  margin-left: -150px;
  margin-top: -25px;
}

@-moz-keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -moz-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -moz-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  60% {
    -moz-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}
@-webkit-keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  60% {
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}
@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -moz-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  60% {
    -moz-transform: translateY(-15px);
    -ms-transform: translateY(-15px);
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}
